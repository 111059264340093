/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, FC, ReactElement, ReactNode, Suspense, createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSlot from '../../../hooks/useSlots';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import withSlot, { SlotDefinitions, SlotType } from '../../../wrappers/withSlot';
import TopNavPortal from '../../layout/top-menu/TopNavPortal';
import { Option } from '../../Option';
import CheckIcon from '../icon/CheckIcon';
import PageLoader from '../page-loader/PageLoader';
import TabButton from './TabButton';
import { useAccordionContext } from '../accordion/Accordion';

type TabHeaderInternalState = {
  selected: boolean;
  onClick: (id: string) => void;
};

type FCSlots<T> = T extends { Slot: SlotType<infer S> } ? { _slots: S } : never;
type FCProps<T> = T extends FC<infer P> ? P : never;
type TabHeaderInternalProps = FCProps<typeof TabHeader> &
  FCSlots<typeof TabHeader> & {
    internalState: TabHeaderInternalState;
    evenTabs?: boolean;
    borderless?: boolean;
    allowWrapping?: boolean;
    bgColor?: string;
  };

const TabHeaderContext = createContext({ selected: false });

export const useTabHeader = () => useContext(TabHeaderContext);

const TabHeaderInternal: FC<TabHeaderInternalProps> = (props) => {
  const { id, internalState, evenTabs, text, value, borderless, allowWrapping, bgColor = '' } = props;

  const leading = useSlot(props, 'Leading');
  const trailing = useSlot(props, 'Trailing');

  return (
    <TabButton
      data-cy={`tab-header-${id}`}
      {...dataAttributeProps(props)}
      key={`tab-${id}`}
      className={`${internalState.selected ? 'selected' : ''} ${bgColor} ${allowWrapping && borderless ? 'gradient-border border-r-2' : ''} ${evenTabs ? 'flex-1' : ''}`}
      onClick={() => internalState.onClick(id)}
      selected={internalState.selected}
      borderless={borderless}
    >
      <TabHeaderContext.Provider value={{ selected: internalState.selected }}>
        <div className="relative flex items-center justify-center gap-2">
          {typeof value === 'boolean' && (
            <div
              className={`rounded-full ${
                value ? 'bg-semantic-1' : ' border-semantic-1 border-2 bg-white'
              } mr-2 flex h-4 w-4 items-center justify-center text-white`}
            >
              <CheckIcon className={`${value ? '' : 'invisible'} h-3 w-3`} />
            </div>
          )}
          <span>{leading()}</span>
          <span className="truncate">{text}</span>
          <span>{trailing()}</span>
        </div>
      </TabHeaderContext.Provider>
    </TabButton>
  );
};

// Make `value` optional
const TabHeader = withSlot<Omit<Option<string, boolean | null>, 'value'> & { value?: boolean | null }, SlotDefinitions<['Leading', 'Trailing']>>(
  () => {
    // Dummy component
    return null;
  },
);

type TabContentProps = {
  forId: string;
  onChange?: () => void;
};

const TabContent: FC<TabContentProps & { children: ReactNode | ReactElement | ((active: boolean) => ReactNode | ReactElement) }> = () => {
  // Dummy component
  return null;
};

const TabContentInternal: FC<
  TabContentProps & { active: boolean; children: ReactNode | ReactElement | ((active: boolean) => ReactNode | ReactElement) }
> = (props) => {
  return (
    <Suspense fallback={<PageLoader loading isSuspense />}>
      {typeof props.children === 'function' ? props.children(props.active) : props.children}
    </Suspense>
  );
};

type TabStripType<P = Record<string, unknown>> = FCWithChildren<P> & {
  TabHeader: typeof TabHeader;
  TabContent: typeof TabContent;
};

type TabStripProps = {
  evenTabs?: boolean;
  centered?: boolean;
  defaultTabId?: string;
  headerClassName?: string;
  contentClassName?: string;
  enableHash?: boolean;
  enableSticky?: boolean;
  wrapper?: FC;
  borderless?: boolean;
  allowWrapping?: boolean;
  overflowHidden?: boolean;
  onChange?: (newTabId: string) => void;
  tabBgColor?: string;
};

export const TabStrip: TabStripType<TabStripProps> = (props) => {
  const {
    children,
    evenTabs,
    defaultTabId,
    contentClassName,
    headerClassName,
    enableHash,
    enableSticky,
    wrapper: Wrapper = 'div',
    centered,
    borderless,
    allowWrapping,
    overflowHidden,
    onChange,
    tabBgColor,
  } = props;
  const isTopNav = Wrapper === TopNavPortal;
  const { updateParentHeight: updateParentAcordion } = useAccordionContext();

  const childrenArr = Children.toArray(children);
  let tabHeaders: any[] = childrenArr.filter(({ type }: any) => type === TabHeader);
  let tabContents: any[] = childrenArr.filter(({ type }: any) => type === TabContent);
  const navigate = useNavigate();
  const location = useLocation();

  if (tabHeaders.length === 0 && tabContents.length === 0) {
    let newChildrenArr: any[] = [];
    for (const el of childrenArr) {
      newChildrenArr = newChildrenArr.concat(Children.toArray((el as any).props.children));
    }

    tabHeaders = newChildrenArr.filter(({ type }: any) => type === TabHeader);
    tabContents = newChildrenArr.filter(({ type }: any) => type === TabContent);
  }

  const [selectedTabId, setSelectedTabId] = useState(defaultTabId || tabHeaders[0]?.props.id);

  useEffect(() => {
    const selectedTab = tabHeaders.find((x) => x.props.id === selectedTabId);
    if (!selectedTab) {
      setSelectedTabId(tabHeaders[0]?.props.id);
    }
  }, [selectedTabId, tabHeaders]);

  useEffect(() => {
    updateParentAcordion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  const handleClick = (tabId: string): void => {
    if (enableHash) {
      navigate({ hash: tabId }, { replace: true });
    }
    setSelectedTabId(tabId);
    const selectedContent = tabContents.find((el) => el.props.forId === tabId);
    selectedContent.props.onChange && selectedContent.props.onChange();
    onChange && onChange(tabId);
  };

  useEffect(() => {
    if (!enableHash) {
      return;
    }

    if (location.hash) {
      const selectedTab = tabHeaders.find((x) => x.props.id === location.hash.substr(1));
      setSelectedTabId(!selectedTab ? tabHeaders[0]?.props.id : location.hash.substr(1));
    } else {
      navigate({ hash: selectedTabId }, { replace: true });
    }
  }, [enableHash, location.hash, navigate, selectedTabId, tabHeaders]);

  const enableStickyHeader = (enableSticky?: boolean): string => {
    if (enableSticky) {
      return (overflowHidden ? 'overflow-hidden' : 'overflow-y-auto') + ' h-0';
    }
    return '';
  };

  return (
    <div className="flex h-full flex-col">
      <Wrapper>
        <div
          className={`flex ${headerClassName} ${centered && 'justify-center'} w-full ${allowWrapping ? 'flex-wrap px-1' : ''}`}
          data-testid="tab-strip-tabs"
        >
          {tabHeaders.map((tab) => (
            <TabHeaderInternal
              key={tab.props.id}
              {...tab.props}
              evenTabs={evenTabs}
              internalState={{ onClick: handleClick, selected: tab.props.id === selectedTabId }}
              borderless={borderless}
              allowWrapping={allowWrapping}
              bgColor={tabBgColor}
            />
          ))}
          {!evenTabs && !centered && !borderless && <div className={`flex-grow ${!isTopNav ? 'border-b-2' : ''}`}></div>}
        </div>
      </Wrapper>
      {tabContents.map((content) => (
        <div
          {...dataAttributeProps(content.props)}
          key={`content-${content.props.forId}`}
          className={`${contentClassName} ${selectedTabId === content.props.forId ? 'flex-grow' : 'hidden'} ${enableStickyHeader(enableSticky)}`}
        >
          <TabContentInternal active={selectedTabId === content.props.forId} {...content.props} />
        </div>
      ))}
    </div>
  );
};

TabStrip.TabHeader = TabHeader;
TabStrip.TabContent = TabContent;
